import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesTargetService {

  private backwardSubject = new BehaviorSubject('');
  backwardChanged = this.backwardSubject.asObservable()
  constructor() { }
}
