import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/keyClock/auth.service';
import { Role } from 'src/app/keyClock/role';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
Role = Role;
   data:any;
   customersCount:any=[];
    loggedUser:any=[];
  constructor(private admin:AdminService,private authService:AuthService,) { 
  }

  ngOnInit() {
    this.loggedUser=sessionStorage.getItem('roles') 
    this.getProductsList();
    // const userDetails = this.authService.getRoles();
    // console.log(userDetails,"user Detailssss")
  }
  getProductsList(){
    if(this.loggedUser.includes('SUPER_ADMIN')){
      // this.spinner.show()
      this.admin.getProducts().subscribe((res1:any)=>{
        // this.spinner.hide();
        this.data = res1
       })
    }else if(!this.loggedUser.includes('SUPER_ADMIN') ){
      // this.spinner.show();
      this.admin.getRoleByProduct().subscribe((res:any)=>{
        // this.spinner.hide();
        this.data =res;
      })
    }
   
  }
  redirectPage(domain:any){
    window.open(domain + '?accessToken=' + sessionStorage.getItem('token') )
  }
}
