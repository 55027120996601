export class loginDetails {
    party_type: any;
    party_name: any;
    role: any;
    password: any;
    email: any;
  }
  
  export class setPassword {
    user_name: any;
    new_password: any;
    confirm_password: any;
    orgCode: any;
    user: any;
  }