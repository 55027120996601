export class customerDetails{
        public OrgName: any;
        public Org_code: any;
        public OrgType:any;
        public cin:any;
        public gstin:any;
        public state:any;
        public orgId:any;
        public district:any;
        public area:any;
        public address:any;
        public Designation:any;
        public MobileNumber:any;
        public Domaindetails:any;
        public email:any;
        public Realm:any;
        public Statelist:any;
        public Product_code:any;
        public index ?: any;
        public indexValidation ?:boolean;
        public port:any;
        public dbName:any;
        public host:any
        public app_code:any
        // public material_type: any
   
}
export class groupsAndRolesDetails{
    constructor(
        public groups:any,
        public index ?:any,
        public indexValidation ?:boolean
         
    ){}
}
export class RolesDetails{
    constructor(
        public roles:any,
        public index ?:any,
        public indexValidation ?:boolean
         
    ){}
}