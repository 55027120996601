import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './keyClock/AppInit';
import { AuthService } from './keyClock/auth.service';
import { HasAnyRoleDirective } from './keyClock/rbac.directive';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { TokenInterceptor } from './token.interceptor';
import { LoginDirective } from './login.directive';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { ProductsComponent } from './components/products/products.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { UsersComponent } from './components/users/users.component';
import { AddUsersComponent } from './components/add-users/add-users.component';
import { ToastrModule } from 'ngx-toastr';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { SendOtpComponent } from './components/send-otp/send-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DetailCellRendererComponent } from './components/detail-cell-renderer/detail-cell-renderer.component';
import { SettingSalesTargetComponent } from './components/setting-sales-target/setting-sales-target.component';






@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    LoginPageComponent,
    LoginDirective,
    AddCustomerComponent,
    ProductsComponent,
    CustomersComponent,
    CustomerListComponent,
    AddUsersComponent,
    UsersComponent,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    SendOtpComponent,
    DetailCellRendererComponent,
    HasAnyRoleDirective,
    SettingSalesTargetComponent,

  ],
  imports: [
    NgOtpInputModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    KeycloakAngularModule,
    AgGridModule,
    ToastrModule.forRoot(),
    // NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    LoginDirective
  ],
  providers: [
    KeycloakService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializer,
    //   multi: true,
    //   deps: [KeycloakService]
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
