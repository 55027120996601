import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { AlertService } from './services/alert.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RWconfig';
  showLoading: boolean;
  showSuccessModal: boolean;
  successmessage: string;
  showErrorModal: boolean;
  errormessage: string;
  constructor(private loadingService: LoadingService, private cdr: ChangeDetectorRef, private alertModal: AlertService) {}

  ngOnInit() {
    console.log(window.history,"history")
    this.loadingService.currentLoadingStatus.subscribe(status => {
      this.showLoading = status;
      this.cdr.detectChanges();
    });

    this.alertModal.successMsg.subscribe(resp => {
      this.showSuccessModal = resp.status;
      if (this.showSuccessModal) {
        this.success_Modal();
      }
    });

    this.alertModal.errMsg.subscribe(resp => {
      this.showErrorModal = resp.status;
      if (this.showErrorModal) {
        this.error_modal();
      }
    });
  }

  success_Modal() {
    this.alertModal.successMsg.subscribe(resp => {
      this.successmessage = resp.msg;
    });
    $('#success').modal({
      show: true,
      keyboard: false,
      backdrop: 'static'
    });
  }

  error_modal() {
    this.alertModal.errMsg.subscribe(resp => {
      this.errormessage = resp.msg;
    });
    $('#error').modal({
      show: true,
      keyboard: false,
      backdrop: 'static'
    });
  }

}
