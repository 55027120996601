import { Component, OnInit } from '@angular/core';
import { customerDetails, groupsAndRolesDetails, RolesDetails } from 'src/app/formModels/customer.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { format } from 'url';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  update: boolean;
  StateList = [];
  Districtlist = [];
  AreaList = [];
  Designationlist = [];
  selectedState = [];
  stateid = [];
  StatedropdownSettings = {};
  districtData: any;
  DistrictdropdownSettings = {};
  AreadropdownSettings = {};
  areadata: any;
  DesignationdropdownSettings = {};
  OrganizationdropdownSettings = {};
  Designationdata: any;
  public fileobject: File;
  model: any = new customerDetails();
  model1: any = new groupsAndRolesDetails(null);
  model2: any = new RolesDetails(null);
  fieldArray: any = []
  fieldArray1: any = [];
  getroleData: any = [];
  OrganizationList: any = [];
  getRoute: any;

  constructor(private service: AdminService, private toastr: ToastrService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getRoute = this.route.snapshot.routeConfig.path;
    if (this.getRoute === 'existing-customer') {
      this.service.getExistingOrganization(sessionStorage.getItem("product_code")).subscribe((res: any) => {
        this.OrganizationList = res;
        this.OrganizationdropdownSettings = {
          singleSelection: true,
          idField: 'org_name',
          textField: 'org_code',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      })
    }
    console.log(this.getRoute, "getroute")
    this.Designation();
    this.state();
    this.fieldArray.push(new groupsAndRolesDetails(null, 0, false));
    this.fieldArray1.push(new RolesDetails(null, 0, false));
    this.model.Product_code = sessionStorage.getItem("product_code")
    this.model.OrgType = 'TENANT'
    this.model.Designation = "TENANT"

  }
  onSelectOrganization(event) {
    console.log(event, "$event")
    console.log(this.model.Org_code, "orgCOdeeee")
    console.log(this.OrganizationList, "orgList")
    this.OrganizationList.filter((item: any) => {
      if (item.org_code === event.org_code) {
        this.model.Org_code = event.org_code
        this.model.OrgName = item.org_name;
        this.model.OrgType = item.org_type;
        this.model.orgId = item.org_id;
        this.model.state = [{ state_id: item.state, state_name: item.state }];
        this.model.gstin = item.gstin;
        this.model.cin = item.cin;
        this.model.district = [{ district_id: item.district, district_name: item.district }];
        this.model.area = [{ area_id: item.area, area_name: item.area }];
        this.model.address = item.address;
        // this.model.Designation = item.designation;
        this.model.MobileNumber = item.mobile_number;
        this.model.email = item.email;
        this.model.Realm = item.realm;
        this.model.domain_details = item.domain_details;
        console.log(this.model.district, this.model.state, "district")
        this.DistrictdropdownSettings = {
          singleSelection: true,
          idField: 'district_id',
          textField: 'district_name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true

        }
        this.AreadropdownSettings = {
          singleSelection: true,
          idField: 'area_id',
          textField: 'area_name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true


        }
      }
    })
  }
  addAnotherCustomer(index) {
    this.fieldArray.push(new groupsAndRolesDetails(null, 0, false));
  }
  deletCustomer(index) {
    this.fieldArray.splice(index, 1);
  }
  addAnotherCustomer1(index) {
    this.fieldArray1.push(new RolesDetails(null, 0, false));
    console.log(this.fieldArray1, "field array1")
  }
  deletCustomer1(index) {
    this.fieldArray1.splice(index, 1);
  }

  state() {
    this.service.getState().subscribe((res: any) => {
      this.selectedState = res;

      this.StatedropdownSettings = {
        singleSelection: true,
        idField: 'state_id',
        textField: 'state_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        closeDropDownOnSelection: true,
        allowSearchFilter: true

      }

    })

  }
  onSelectState(item: any) {
    console.log(item, "state")
   this.StateList  = item
    let stateId = {
      "state_id": item.state_id
    }
    console.log(item.state_id);
    this.service.getDistrict(stateId).subscribe((res) => {
      console.log(res);
      this.districtData = res;
      this.DistrictdropdownSettings = {
        singleSelection: true,
        idField: 'district_id',
        textField: 'district_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        closeDropDownOnSelection: true,
        allowSearchFilter: true

      }

    })

  }
  onSelectDistrict(item: any) {
    console.log(item, "districtttt")
    this.Districtlist = item
    let districtId = {
      "district_id": item.district_id
    }
    this.service.getArea(districtId).subscribe((res) => {
      console.log(res)
      this.areadata = res;
      console.log(this.areadata)
      this.AreadropdownSettings = {
        singleSelection: true,
        idField: 'area_id',
        textField: 'area_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        closeDropDownOnSelection: true,
        allowSearchFilter: true


      }


    })

  }
  onSelectArea(item:any){
    this.AreaList = item
    console.log(item,"area")
  }
  Designation() {
    console.log("hi")
    this.service.getDesignation().subscribe((res) => {
      this.Designationdata = res;
      let x = delete this.Designation[2]

      this.DesignationdropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'role_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        closeDropDownOnSelection: true,
        allowSearchFilter: true
      }
      this.getroleData = sessionStorage.getItem("roles")
    })
  }
  onChange(event) {
    this.fileobject = event.target.files[0];
    console.log(this.fileobject, "fileObject")
  }

  submit(form: NgForm) {
    if (form.invalid) { return true }
   
    if (this.getRoute === 'existing-customer') {
      let createOrgData = {
        "org_name": this.model.OrgName,
        "org_type": this.model.OrgType,
        "org_id": this.model.Org_code,
        "org_code": this.model.Org_code,
        "cin": this.model.cin,
        "gstin": this.model.gstin,
        "state": this.model.state[0].state_id,
        "district": this.model.district[0].district_id,
        "area": this.model.area[0].area_id,
        "address": this.model.address,
        "role": this.model.Designation,
        "mobile_number": this.model.MobileNumber,
        "email": this.model.email,
        "product_code": this.model.Product_code,
        "realm": this.model.Realm,
        "domain_details": this.model.DomainDetails,
        "app_code": this.model.app_code,
        "mongo_host": this.model.dbName,
        "mongo_port": this.model.port,
        "mongo_db_name": this.model.host
  
      }
      this.service.existingCustomerProduct(createOrgData).subscribe((res: any) => {
        console.log(res, "ressppp for organization")
      })
    }else if(this.getRoute != 'existing-customer'){
      this.model.state = this.StateList['state_name']
      this.model.district = this.Districtlist['district_name']
      this.model.area = this.AreaList['area_name']
      console.log(this.StateList,this.Districtlist,this.AreaList,"state area district")
      let createOrgData = {
        "org_name": this.model.OrgName,
        "org_type": this.model.OrgType,
        "org_id": this.model.Org_code,
        "org_code": this.model.Org_code,
        "cin": this.model.cin,
        "gstin": this.model.gstin,
        "state": this.model.state.state_name,
        "district": this.model.district.district_name,
        "area": this.model.area.area_name,
        "address": this.model.address,
        "role_code": this.model.Designation,
        "mobile_number": this.model.MobileNumber,
        "email": this.model.email,
        "product_code": this.model.Product_code,
        "realm": this.model.Realm,
        "domain_details": this.model.DomainDetails,
        "app_code": this.model.app_code,
        "mongo_host": this.model.host,
        "mongo_port": this.model.port,
        "mongo_db_name": this.model.dbName
  
      }
      this.service.createNewOrganization(createOrgData).subscribe((res:any)=>{
        console.log(res,"res for new organization")
      })
    }


  }
}





