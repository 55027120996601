export class salesTarget{
   sales_target_for:any;
   sales_target_by:any;
   sales_target_for1:any;
   set_target_amount:any;
   manager_type:any;
   allot_target_by:any;
   pending_allocation:any;
   set_percentage:any;
   category_target_by:any;
   set_target_by:any;
   select_category:any;
   set_target_quantity:any;
   region:any;
   split_target_amoun:any;
   manager_name1:any;

}
export class managerDetails {
   constructor(
       public manager_name: any,
       public set_target_amount_data:any,
       public split_target_amount_data ? :any,
       public index ?: any,
       public indexValidation ?:boolean
       // public material_type: any
   ) { }
}
export class AccessType{
   accessType:any
}