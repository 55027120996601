import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  configUrl = undefined;
  apiUrl = undefined;
  baseUrl1:any = environment.keycloakConfig.url + '/realms/' + environment.keycloakConfig.realm
  baseUrl:any = 'http://localhost:3000/'
  constructor() {
    this.configUrl = environment.configUrl;
    this.apiUrl = environment.apiUrl;
    
   }
}
