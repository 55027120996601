import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forgotpassword } from 'src/app/formModels/forgotpassword.model';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  model = new forgotpassword(null,null,);

  userName: any;
  showperr: boolean = false;
  showperr2: boolean = false;

  constructor(private route: Router) { }

  ngOnInit() {
  }

  submit(){
    // this.route.navigate([''])

  }

  inputChange(){

  }
  inputChange2(){

  }
}
