import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from './role';
import { AuthService } from './auth.service';

@Directive({
    selector: '[hasAnyRole]'
})
export class HasAnyRoleDirective {
    
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private authService: AuthService) {}

    @Input() set hasAnyRole(allowedRoles: Role[]) { // ADMIN, CATERING
        if (typeof allowedRoles === undefined) {
            this.viewContainer.clear();
            return;
        }
        
        if (allowedRoles.length === 0) {
            this.viewContainer.clear();
            return;
        }
        
        const isLoggedIn = this.authService.getLogeedRoles();
        if (isLoggedIn === undefined) {
            this.viewContainer.clear();
            return;
        }

        const loggedRoles = this.authService.getLogeedRoles(); //[ADMIN]
        console.log('LoggedRoles', loggedRoles);
        for (const role of allowedRoles) {
            if (loggedRoles.indexOf(role.toString()) > -1) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                return;
            }
        }

        this.viewContainer.clear();
    }
}