import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingStatus = new BehaviorSubject(false);
  currentLoadingStatus = this.loadingStatus.asObservable();
   hlfNetwork:any;
  
  constructor() { }

  changeLoadingStatus(status: boolean) {
    this.loadingStatus.next(status);
  }
  // set(data) {
  //   this.hlfNetwork= data;
  // }
  // get(){
  //   return this.hlfNetwork;
  // }
  
}
