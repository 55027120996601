import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-otp',
  templateUrl: './send-otp.component.html',
  styleUrls: ['./send-otp.component.scss']
})
export class SendOtpComponent implements OnInit {
  otpPin:any
  obj:any

  constructor(private route: Router,) { }

  ngOnInit() {
  }

  otpSubmit(){
    // if(this.otpPin===undefined || this.otpPin===null){
    //   this.toaster.error("Please Enter OTP")
    //   return;
    // }
    // if(this.otpPin.length<6){
    //   this.toaster.error("Please Enter All Digits")
    //   return;
    }

    onOtpChange(){

    }
    resendOtp(){
      
    }
  

}
