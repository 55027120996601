import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {forgotpassword} from 'src/app/formModels/forgotpassword.model'
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private route: Router) { }
  Email='';
  model = new forgotpassword(null,null);

  ngOnInit() {
  }
  submit(){
    this.route.navigate(['sendOTP'])
    
    
    
    
    }
    inputChange(){

    }

    inputChange2(){
      
    }

}
