import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private successMessage = new BehaviorSubject({msg: 'default Message', status: false});
  successMsg = this.successMessage.asObservable();

  private errorMessage = new BehaviorSubject({msg: 'default Message', status: false});
  errMsg = this.errorMessage.asObservable();

  constructor() { }

  successAlert(message, status) {
    this.successMessage.next({msg: message, status});
  }

  errorAlert(message: string, status: boolean) {
    this.errorMessage.next({msg: message, status});
  }

}
