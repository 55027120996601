
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { loginDetails } from 'src/app/formModels/login-model';
import { AuthService } from 'src/app/keyClock/auth.service';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  model = new loginDetails();
  constructor(private route: Router,private  router:ActivatedRoute, private authService: AuthService, private service: AdminService,private toastr: ToastrService) { }
  ngOnInit() {
    this.router.params.subscribe( params => {
      console.log(params,"realmmm")
      if(params['realm'] != ':realm'){
        sessionStorage.setItem("realm",params['realm'])
      }
     })
  }
  submit(form: NgForm) {
    if (form.valid) {
      let data = {
        "client_id": environment.keycloakConfig.clientId,
        "username": this.model.email,
        "password": this.model.password,
        "grant_type": "password",
        "redirect_uri": window.location.protocol + '//' + window.location.host,
        // "redirect_uri": 'https://config.rr-dlr-1.uat.dizitwin.com/'

        // "grant_type": "authorization_code",
        // "client_id": "rw_config_ui",
        // "redirect_uri": "https://config.rr-dlr-1.uat.dizitwin.com/"
      }
      console.log(data, "submit data")
    
      this.service.loginSubmit(data,sessionStorage.getItem("realm")).subscribe((res: any) => {
        console.log(res, "logindata")
        if (res.access_token) {
          var decoder = jwt_decode(res.access_token)
          this.authService.setToken(res.access_token)
          this.authService.loginStateChanged()
          this.authService.login(res.access_token);
          console.log(decoder, "decoder")
          sessionStorage.setItem("roles", decoder['realm_access'].roles)
          sessionStorage.setItem('preferred_username',decoder['preferred_username'])
          
        }
        if (sessionStorage.getItem('token')) {
          this.toastr.success("Success")
          return this.route.navigate([''])
        }else{
          this.route.navigate(['/login/:realm']);
          // return false;
        }
       
      }, (error) => {



      }
      )


    }

  }
  reset() {
    // this.model.user=''
    // this.model.password=''

  }
}
