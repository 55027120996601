import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  getCustomer: any = [];
  customerProduct: any;
  gridApi;
  gridColumnApi;
  columnDefs;
  defaultColDef;
  detailCellRendererParams;
  rowHeight;
  pagination = true;
  paginationPageSize;
  sideBar;
  autoGroupColumnDef;
  excelStyles;
  sortModel: { colId: string; sort: string; }[];
  maxBlocksInCache: number;
  chartThemeOverrides;
  popupParent: HTMLElement;
  icons: any;
  domLayout;
  rowSelection;
  defaultExportParams;
  customerInfoData:any=[];
  constructor(private adminService: AdminService, private route: ActivatedRoute) {
    this.domLayout = 'autoHeight';
  }

  ngOnInit() {
    this.customerProduct = this.route.snapshot.queryParams.ProductCode;
    sessionStorage.setItem("product_code", this.customerProduct)
    this.adminService.getProductByCustomer().subscribe((res: any) => {
      res.filter((item: any) => {
        if (item.product_code === this.customerProduct) {
          this.getCustomer.push(item);
        } else {
        }
      })
    })
  }
  closemodal() {

    document.getElementById('myNav').style.width = '0';
    this.customerInfoData =[]
  }
  customerInfo(data) {
    console.log(data,"dattatattaa")
    this.columnDefs = [
      {
        headerName: 'ORGANISATION DETAILS',
        children: [
          {
            headerName: 'ORGANISATION NAME',
            field: 'org_name',
            filter: true,
            headerTooltip: 'ORGANISATION NAME',
            tooltipField: 'org_name',

          },
          {
            headerName: 'ORGANISATION CODE',
            field: 'org_code',
            columnGroupShow: 'open',
            filter: true,
            headerTooltip: 'ORGANISATION CODE',
            tooltipField: 'org_code',
          },
          {
            headerName: 'ORGANISATION TYPE',
            field: 'org_type',
            columnGroupShow: 'open',
            filter: true,
            headerTooltip: 'ORGANISATION TYPE',
            tooltipField: 'org_type',
          },
        ]
      },

      {
        headerName: 'PRODUCT NAME',
        field: 'product_name',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'PRODUCT NAME',
        tooltipField: 'product_name',
      },
      {
        headerName: 'REALM',
        field: 'realm',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'REALM',
        tooltipField: 'realm',
      },
      {
        headerName: 'DOMAIN',
        field: 'domain_details',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'DOMAIN',
        tooltipField: 'domain_details',
      },
      {
        headerName: 'APP CODE',
        field: 'app_code',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'APP CODE',
        tooltipField: 'app_code',
      },
      {
        headerName: 'MONGO DB NAME',
        field: 'mongo_db_name',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'MONGO DB NAME',
        tooltipField: 'mongo_db_name',
      },


    ];
    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },
      ],

    };
    this.detailCellRendererParams = {
      detailGridOptions: {
        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },
        checkbox: true
      },
    };
    this.customerInfoData.push(data)
    document.getElementById('myNav').style.width = '70%';
  }
}
