import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (sessionStorage.getItem('token') !== null) {
      const token = sessionStorage.getItem('token');
      // if the token is stored in sessionstorage add it to http header
      const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
      //clone http to the custom AuthRequest and send it to the server 
      const AuthRequest = request.clone({ headers: headers });

      return next.handle(AuthRequest);
    } else {
      return next.handle(request);

    }
  }
}
