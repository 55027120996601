import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loginState = new Subject();
  token: any;
  userDetails:any=[];
  permission: any = [];
  constructor(private keycloakService: KeycloakService, private router:Router) { 
   
  }

  // setLoginState(state: number) {
  //   if (state === 0) {
  //     localStorage.removeItem('loginStatus');
  //   } else {
  //     localStorage.setItem('loginStatus', state+"");
  //   }
  //   this.loginStateChanged();
  // }

  // getLoginState(): boolean {
  //   return (localStorage.getItem('loginStatus') !== undefined && localStorage.getItem('loginStatus') !== null);
  // }

  // loginStateChanged() {
  //   this.loginStateChange.next();
  // }
  // getLoggedIn() {

  //   if (sessionStorage.getItem("token") !== undefined && sessionStorage.getItem("token") !== null) {
  //     return true
  //   }
  //   else {
  //    return false
  //   }
  // }
  // setToken(token) {
  //   this.token = token;
  //   sessionStorage.setItem("token", token);
  // }
  // login(user:any){
  //   if(user){
  //     this.router.navigate([''])
  //   }
  // }
  getLoggedUser() {
    // try {
    //   let userDetails = this.keycloakService.getKeycloakInstance().idTokenParsed;
    //   return userDetails;
    // } catch (e) {
    //   console.log('getLoggedUser Exception', e);
    //   return undefined;
    // }
  }

  // logout() {
  //   sessionStorage.clear();
  //   sessionStorage.clear();
  //   this.router.navigate(['/login'])
  //   // localStorage.removeItem('userName')
  //   // this.loginStateChanged()
  //   // this.getLoggedIn()
  //   this.keycloakService.logout()
  // }

  // redirectToProfile() {
  //   this.keycloakService.getKeycloakInstance().accountManagement()
  // }

  getRoles(): string[] {
    console.log(this.keycloakService.getUserRoles(),"get user roles")
    return this.keycloakService.getUserRoles();
  }



  // get isLoggedIn() {
  //   return this.loggedIn.asObservable();
  // }
  // public get loggedInUser(): boolean {
  //   return (sessionStorage.getItem('token') !== null);
  // }









  login(user:any){
      if(user){
        this.router.navigate([''])
      }
    }

 
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  public get loggedInUser(): boolean {
    return (sessionStorage.getItem('token') !== null);
  }

  loginStateChanged() {
    this.loginState.next();
  }
  setToken(token) {
    this.token = token;
    sessionStorage.setItem("token", token);
  }
  getToken() {
    return this.token;
  }
  getLoggedIn() {

    if (sessionStorage.getItem("token") !== undefined && sessionStorage.getItem("token") !== null) {
      return true
    }
    else {
     return false
    }
  }
  setRoles(roles?:any){
    try {
      let rolesdata = sessionStorage.setItem("roles",roles)
      this.userDetails = rolesdata;
    } catch (e) {
      console.log('getLoggedUser Exception', e);
      return undefined;
    }
  }
  getLogeedRoles(){
    return sessionStorage.getItem('roles')
  }
  // setUserLoggedIn(userLoggedIn: boolean) {
  //   this.userLoggedIn.next(userLoggedIn);
  // }

  // getUserLoggedIn(): Observable<boolean> {
  //   return this.userLoggedIn.asObservable();
  // }

  logout() {
    sessionStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login/:realm'])
    // localStorage.removeItem('userName')
    this.loginStateChanged()
    // this.getLoggedIn()
    this.keycloakService.logout()
  }

}
