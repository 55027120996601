// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloakConfig: {
    clientId: 'realware-config-ui',
    realm: 'REALWARE_CONFIG',
    url: 'https://auth.innovation.realware.app/auth'
  },
  keyclockInitOptions: {
    silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
  },
  configUrl: 'https://api.config.dev-rw.dizitwin.com/',
  apiUrl: 'https://api.generic.dev-rw.dizitwin.com/',
  configurerRole: 'realware-config-admin'
};

// https://auth.innovation.realware.app/auth/realms/REALWARE_CONFIG/account/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
