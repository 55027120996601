import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './keyClock/auth.guard';
import { environment } from 'src/environments/environment';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { ProductsComponent } from './components/products/products.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { AddUsersComponent } from './components/add-users/add-users.component';
import { UsersComponent } from './components/users/users.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { SendOtpComponent } from './components/send-otp/send-otp.component';
import { SettingSalesTargetComponent } from './components/setting-sales-target/setting-sales-target.component';



const routes: Routes = [


  {
    path:'productCustomers',
    
    children:[
      {path:'',component:CustomersComponent},
      {
        path:'add-customer',
        component:AddCustomerComponent
      },
      {
        
          path:'existing-customer',
          component:AddCustomerComponent
        
      }
    ]
  },
  
  
  {
    path:'CustomerUsers',
    children:[
      {path:'',component:UsersComponent},
      {path:'add-user',component:AddUsersComponent
    }
    ]
  },
  {
    path:'sales-target',
    component:SettingSalesTargetComponent
  },
  
  {
    path:'customersList',
    component:CustomerListComponent
  },
  {
    path:'login/:realm',
    component:LoginPageComponent
  },
  {
    path:'',
    component:ProductsComponent
  },
  {
    path:'forgotpassword',
    component:ForgotpasswordComponent
  },
  {
    path:'chnagepassowrd',
    component:ChangepasswordComponent
  },
  {
    path:'sendOTP',
    component:SendOtpComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
