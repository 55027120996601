import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './keyClock/auth.service';

@Directive({
  selector: '[appLogin]'
})
export class LoginDirective {
constructor(private authservice: AuthService, private templateRef: TemplateRef<any>,private viewContainer: ViewContainerRef, private router: Router) { }
@Input() set appLogin(obj: any) {
    const dis = this;
    this.authservice.loginState.subscribe(a => {
      const isLoggedIn = dis.authservice.getLoggedIn();
      if (!isLoggedIn) {
        this.viewContainer.clear();
        this.router.navigate(['login/:realm'])
        return;
      }
      this.viewContainer.createEmbeddedView(this.templateRef);
    })
    const isLoggedIn = dis.authservice.getLoggedIn();
    if (!isLoggedIn) {
      this.viewContainer.clear();
      this.router.navigate(['login/:realm'])
      return;
    }
    this.viewContainer.createEmbeddedView(this.templateRef);

  }

}
