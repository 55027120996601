import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AccessType, managerDetails, salesTarget } from 'src/app/formModels/sales-target.model';
import { SalesTargetService } from 'src/app/services/sales-target.service';
declare var $: any;
@Component({
  selector: 'app-setting-sales-target',
  templateUrl: './setting-sales-target.component.html',
  styleUrls: ['./setting-sales-target.component.scss']
})
export class SettingSalesTargetComponent implements OnInit {
  SalesTargetForSettings = {};
  salesTargetBySettings = {};
  managerTypeSettings = {};
  managerNameSettings = {};
  allotTargetBySettings = {};
  salesTargetForData: any = [];
  salesTargetByData: any = [];
  setTargetBySettings: any = {}
  SalesTargetFor1Settings = {};
  categoryTargetBySettings = {};
  regionTypeSettings = {};
  categoryTargetByData: any = [];
  salesTargetFor1Data: any = [];
  setTargetByData: any = []
  mangerNameData: any = [];
  managerTypeData: any = [];
  allotTargetByData: any = [];
  regionTypeData: any = []
  model = new salesTarget();
  is_set_target_amount = false
  is_manager_type = false
  is_allot_target_by = false;
  is_pending_allocation = false;
  is_category_target_by = false;
  is_set_percentage = false;
  is_manager_Name = false;
  is_target_by = false;
  is_select_category = false;
  is_split_data = false;
  is_region = false;
  fieldArray: any = [];
  is_manager_name1 = false;
  managerName1Settings = {}
  mangerName1Data: any = [];
  is_set_amount = false;
  status:any;
  model1: managerDetails = new managerDetails(null, null)
  model2: AccessType = new AccessType()
  constructor(private service: SalesTargetService) { }

  ngOnInit() {
    this.model.sales_target_for = 'Secondary';
    this.model2.accessType = 'SET TARGET'
    this.salesTargetForDropdown();
    this.setTargetByDropdown()
    this.managerNameDropdown();
    this.allotTargetByDropdown();
    this.salesTargetByDropdown();
    this.categoryTargetByDropdown()
    this.service.backwardChanged.subscribe((res: any) => {
      let status = res;
      if (status !== 'createSalesTargets' && status !== '') {
        this.changeStatus(status);
        // this.changeStatus1(this.status1);
      } else if (status === '') {
        this.changeStatus('createSalesTargets');
        // this.changeStatus1('Organization')
      }
    });
  }
  salesTargetForDropdown() {
    this.salesTargetForData = [{ item_id: 'P1', item_name: 'P1' },
    { item_id: 'P2', item_name: 'P2' },
    { item_id: 'Secondary', item_name: 'Secondary' }]
    this.SalesTargetForSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  salesTargetByDropdown() {
    this.salesTargetByData = [{ item_id: 'Revenues', item_name: 'Revenues' },
    { item_id: 'Products', item_name: 'Products' }]
    this.salesTargetBySettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  salesTargetFor1Dropdown() {
    if (this.model2.accessType === 'SET TARGET') {
      this.salesTargetFor1Data = [{ item_id: 'Organisation', item_name: 'Organisation' },
      { item_id: 'Managers', item_name: 'Managers' },
      { item_id: 'Employees', item_name: 'Employees' }]
    } else {
      this.salesTargetFor1Data = [
        { item_id: 'Managers', item_name: 'Managers' },
        { item_id: 'Employees', item_name: 'Employees' }]
    }

    this.SalesTargetFor1Settings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  managerTypeDropdown() {
    this.managerTypeData = [{ item_id: 'ZSM', item_name: 'ZSM' },
    { item_id: 'RSM', item_name: 'RSM' },
    { item_id: 'ASM', item_name: 'ASM' },
    { item_id: 'TL', item_name: 'TL' }]
    this.managerTypeSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  managerNameDropdown() {
    this.mangerNameData = [{ item_id: 'ZSM1', item_name: 'ZSM1' },
    { item_id: 'ZSM2', item_name: 'ZSM2' },
    { item_id: 'ZSM3', item_name: 'ZSM3' },
    { item_id: 'ZSM4', item_name: 'ZSM4' }]
    this.managerNameSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  allotTargetByDropdown() {
    this.allotTargetByData = [{ item_id: 'Percentage', item_name: 'Percentage' },
    { item_id: 'Value', item_name: 'Value' }]
    this.allotTargetBySettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  onSelectTargetFor(event) {

    if (event.item_id === "Secondary") {

    }
  }
  onSelectSalesTagetfor(event) {
    this.model.set_target_by = null;
    this.model.category_target_by = null;
    this.model.manager_type = null
    this.is_manager_name1 = false;
    this.model.manager_name1 = null
    this.getSetting_and_Splliting()

  }
  onSelectManagerType(event) {
    console.log(this.model2.accessType, "accestype")
    if (this.model2.accessType === 'SET TARGET' && this.model.sales_target_by[0].item_id != 'Products') {
      this.fieldArray.push(new managerDetails(null, null, 0, false));
      this.is_manager_Name = true;

      console.log(this.fieldArray, "field array")
    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_by[0].item_id != 'Products') {
      this.fieldArray.push(new managerDetails(null, null, 0, false));
      this.is_manager_Name = true
      this.is_set_percentage = true
    } else if (this.model2.accessType === 'SET TARGET' && this.model.manager_type[0].item_id != 'ZSM' && this.model.sales_target_by[0].item_id === 'Products') {
      this.regionTypeDropdown();
      this.is_target_by = true;
      this.is_manager_Name = false
      this.is_select_category = true;
      this.is_region = true;
      this.managerName1Dropdown()
      this.is_manager_name1 = true
      this.fieldArray.push(new managerDetails(null, null, 0, false))
    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_by[0].item_id === 'Products' && this.model.manager_type[0].item_id != 'ZSM') {
      this.regionTypeDropdown();
      this.is_target_by = true;
      this.is_manager_Name = false
      this.is_set_target_amount = false
      this.is_select_category = true;
      this.is_allot_target_by = true;
      this.is_pending_allocation = true
      this.is_split_data = true
      this.is_region = true
      this.is_manager_name1 = true
      this.managerName1Dropdown()
      this.fieldArray.push(new managerDetails(null, null, 0, false))
    }
  }
  regionTypeDropdown() {
    this.regionTypeData = [{ item_id: 'South', item_name: 'South' },
    { item_id: 'West', item_name: 'West' },
    { item_id: 'North', item_name: 'North' },
    { item_id: 'East', item_name: 'East' }]
    this.regionTypeSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  managerName1Dropdown() {
    this.mangerName1Data = [{ item_id: 'ZSM1', item_name: 'ZSM1' },
    { item_id: 'ZSM2', item_name: 'ZSM2' },
    { item_id: 'ZSM3', item_name: 'ZSM3' },
    { item_id: 'ZSM4', item_name: 'ZSM4' }]
    this.managerName1Settings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  onSelectSalestargetBy(event) {
    this.is_set_target_amount = false;
    this.model.sales_target_for1 = null;
    this.is_target_by = false;
    this.is_category_target_by = false;
    this.is_manager_type = false;
    this.is_region = false;
    this.model.region = null;
    this.model.allot_target_by = null
    this.salesTargetFor1Dropdown();
  }
  onSelectRegionType(event) { }
  setTargetByDropdown() {
    this.setTargetByData = [{ item_id: 'Category', item_name: 'Category' },
    { item_id: 'Brand', item_name: 'Brand' },
    { item_id: 'SubBrand', item_name: 'SubBrand' }, {
      item_id: 'Item', item_name: 'Item'
    }]
    this.setTargetBySettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  categoryTargetByDropdown() {
    this.categoryTargetByData = [{ item_id: 'Revenue', item_name: 'Revenue' },
    { item_id: 'Quantity', item_name: 'Quantity' }]
    this.categoryTargetBySettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }
  getSetting_and_Splliting() {
    if (this.model2.accessType === 'SET TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Revenues' && this.model.sales_target_for1[0].item_id === 'Organisation') {
      this.is_set_target_amount = true;
      this.is_manager_Name = false;
      this.is_category_target_by = false;
      this.is_manager_type = false;
      this.is_target_by = false;
      this.is_category_target_by = false;
      this.is_select_category = false;
      this.model.manager_type = null;
      this.model.set_target_by = null;
      this.model.category_target_by = null;
      this.is_region = false;
      this.is_target_by = false;
      this.model.set_target_by = null
      this.model.region = null
      this.model.set_target_by = null
      this.fieldArray = []
    } else if (this.model2.accessType === 'SET TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Revenues' && this.model.sales_target_for1[0].item_id === 'Managers') {
      this.is_set_target_amount = false;
      this.is_manager_type = true;
      this.is_allot_target_by = false;
      this.is_pending_allocation = false;
      this.is_category_target_by = false;
      this.is_set_percentage = false;
      this.model.manager_type = null
      this.managerTypeDropdown()
    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Revenues' && this.model.sales_target_for1[0].item_id === 'Managers') {
      this.is_allot_target_by = true;
      this.is_manager_type = true;
      this.is_manager_Name = true;
      this.is_pending_allocation = true;
      this.is_set_target_amount = false;
      this.model.allot_target_by = null
      this.is_set_amount = true
      this.managerTypeDropdown();
      this.fieldArray = []
      // this.fieldArray.push(new managerDetails(null,null,0,false))
    } else if (this.model2.accessType === 'SET TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Products' && this.model.sales_target_for1[0].item_id === 'Organisation') {
      this.is_target_by = true;
      this.is_select_category = true
      this.is_category_target_by = true;
      this.is_manager_type = false;
      this.model.region = null;
      this.is_manager_Name = false
      this.is_region = false;
      this.model.category_target_by = null
      this.fieldArray = []
      this.fieldArray.push(new managerDetails(null, null, 0, false));
    } else if (this.model2.accessType === 'SET TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Products' && this.model.sales_target_for1[0].item_id === 'Managers') {
      this.is_manager_type = true;
      this.is_category_target_by = true;
      this.is_target_by = false;
      this.model.manager_type = null;
      this.model.category_target_by = null;
      this.model.region = null;
      this.is_manager_Name = false;
      this.fieldArray = []
      this.managerTypeDropdown()
      // this.fieldArray.push(new managerDetails(null, null, 0, false));
    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Revenues' && this.model.sales_target_for1[0].item_id === 'Organisation') {
      this.is_set_target_amount = true;
      this.is_manager_type = false;
      this.model.manager_type = null;
      this.is_allot_target_by = false;
      this.fieldArray = [];
      this.is_category_target_by = false;
      this.is_select_category = false

    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Products' && this.model.sales_target_for1[0].item_id === 'Organisation') {
      this.is_set_target_amount = false;
      this.is_manager_type = false;
      this.model.manager_type = null;
      this.is_allot_target_by = false;
      this.fieldArray = [];
      this.is_category_target_by = false;
      this.is_select_category = false;
      this.model.allot_target_by = null

    } else if (this.model2.accessType === 'SPLIT TARGET' && this.model.sales_target_for === 'Secondary' && this.model.sales_target_by[0].item_id === 'Products' && this.model.sales_target_for1[0].item_id === 'Managers') {
      this.is_set_target_amount = false;
      this.is_manager_type = true;
      this.model.manager_type = null;
      this.is_allot_target_by = true;
      this.fieldArray = [];
      this.is_category_target_by = false;
      this.is_select_category = false;
      this.model.allot_target_by = null;
      // this.is_region = true
      this.is_set_percentage = true
      this.is_set_amount = false
      this.is_target_by = true
      this.managerTypeDropdown()

    }
  }
  accessType(event) {
    this.model = new salesTarget();
    this.model1 = new managerDetails(null, null);
    this.is_manager_type = false;
    this.is_manager_Name = false;
    this.is_allot_target_by = false;
    this.is_category_target_by = false;
    this.is_manager_Name = false;
    this.is_manager_type = false;
    this.is_region = false;
    this.is_manager_name1 = false;
    this.is_target_by = false
    this.model2.accessType = event.target.value
    this.model.sales_target_for = 'Secondary'
    console.log(this.model2.accessType, "accessType")
    this.fieldArray = []
  }
  onSelectCategoryTargetBy(event) { }
  addManagerInfo(e) {

    this.fieldArray.push(new managerDetails(null, null, 0, false));
  }
  deletManagerInfo(index) {
    this.fieldArray.splice(index, 1);
  }
  onTargetForDeSelect(event) {
    this.model = new salesTarget();
    this.model1 = new managerDetails(null, null)
    console.log(this.model, "model data")
  }
  onDeSelectTargetBy(event) {
    this.model.sales_target_by = null;
    this.model.sales_target_for1 = null;
    this.model.set_target_amount = null;
    this.model.manager_type = null;
    this.model.region = null;
    this.model.category_target_by = null;
    this.model1 = new managerDetails(null, null);

  }



  AddDetails(form) {
    if (form.valid) {
      $('#staticBackdrop').modal('show');
      console.log(this.fieldArray, "field array")
      if (this.fieldArray.length === 0) {
        console.log(this.fieldArray, "field1 array")
        this.fieldArray.push(new managerDetails(null, null, 0, false))
      }
    } else {
      return
    }

  }
  close() {
    $('#staticBackdrop').modal('hide');
    this.fieldArray = []
    // this.fieldArray.push(new managerDetails(null, null, 0, false))
  }
  onDeSelectManagerType(event) {
    this.model.category_target_by = null
    this.model.set_target_by = null;
    this.model.region = null;
  }
  onDeSelectRegionType(event) {
    this.model.set_target_by = null;
    this.model.category_target_by = null
  }
  onSelectManagerName1(event) {

  }
  submit(form) { }
  changeStatus(event) {
    this.status = event
  }
}
