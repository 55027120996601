import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/keyClock/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  name: any;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    // const userDetails = this.authService.getLoggedUser();
    // tslint:disable-next-line: no-string-literal
    // this.name = userDetails['name'];
    // console.log('Details: ', userDetails);
  }

  logout() {
    console.log('xxxxxxxxxxxxxx');
    this.authService.logout();
    console.log('yyyyyyyyyyyyyyy');
  }

}
