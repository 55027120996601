import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  gridApi;
  gridColumnApi;
  columnDefs;
  defaultColDef;
  detailCellRendererParams;
  rowHeight;
  pagination = true;
  paginationPageSize;
  sideBar;
  autoGroupColumnDef;
  excelStyles;
  sortModel: { colId: string; sort: string; }[];
  maxBlocksInCache: number;
  chartThemeOverrides;
  popupParent: HTMLElement;
  icons: any;
  domLayout;
  rowSelection;
  defaultExportParams;
  getSupplierList;
  constructor(private adminService:AdminService) { }

  ngOnInit() {
    this.getCustomersList();
    this.domLayout = 'autoHeight';
    this.columnDefs = [
      {
        headerName: 'ORGANISATION NAME',
        field: 'org_name',
        columnGroupShow: 'open',
        filter: true,
        headerTooltip: 'ORGANISATION NAME',
        tooltipField: 'partner_type',
      },
      {
        headerName: 'ORGANISATION CODE',
        field: 'org_code',
        columnGroupShow: 'open',
        filter: true,
        headerTooltip: 'ORGANISATION CODE',
        tooltipField: 'partner_id',
      },
      {
        headerName: 'PRODUCT NAME',
        field: 'product_name',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'PRODUCT NAME',
        tooltipField: 'partner_name',
      },
      {
        headerName: 'PRODUCT CODE',
        field: 'product_code',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'PRODUCT CODE',
        tooltipField: 'location_type',
      },
      {
        headerName: 'EMAIL',
        field: 'email',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'EMAIL',
        tooltipField: 'location',
      },
      {
        headerName: 'PHONE NUMBER',
        field: 'mobile_number',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'PHONE NUMBER',
        tooltipField: 'location',
      },
      {
        headerName: 'ROLE',
        field: 'org_name',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'ROLE',
        tooltipField: 'location',
      },
      {
        headerName: 'REALM',
        field: 'realm',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'REALM',
        tooltipField: 'location',
      },
      {
        headerName: 'DOMAIN',
        field: 'domain_details',
        filter: true,
        chartDataType: 'category',
        headerTooltip: 'DOMAIN',
        tooltipField: 'location',
      },
      
    ];
    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },
      ],

    };
    this.detailCellRendererParams = {
      detailGridOptions: {
        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },
        checkbox: true
      },
    };
  }
  getCustomersList(){
    this.adminService.GetAllCoustomer().subscribe((res)=>{
      console.log(res,"orgdsts")
    
      
      this.getSupplierList=res;
      console.log(this.getSupplierList)
     
    })
    
  }

}
