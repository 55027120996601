import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  token = sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('token')
  headers = new HttpHeaders().set('Authorization', this.token)
  constructor(private http: HttpClient) {
    super();
  }

  objectList(appCode, type) {
    const url = this.configUrl + 'data-objects/appCode';
    return this.http.get(url, { params: { appCode, type } });
  }

  //  data_objects_type(appCode, type) {
  //   const url = this.configUrl + 'data-objects/code';
  //   return this.http.get(url, {params: {appCode, type}});
  //  }

  data_Objects(appCode) {
    const url = this.configUrl + 'data-objects/code';
    return this.http.get(url, { params: { type: 'DATA_OBJECT', appCode } });
  }

  requestobject(appCode: any) {
    const url = this.configUrl + 'data-objects/code';
    return this.http.get(url, { params: { type: 'REQUEST_OBJECT', appCode } });
  }

  responseobject(appCode: any) {
    const url = this.configUrl + 'data-objects';
    return this.http.get(url, { params: { type: 'RESPONSE_OBJECT', appappCode: appCode } });
  }
  // ----------------------------------------------------------------------feature------------------------------------------------------
  ftrCreate(obj: any) {
    const url = this.configUrl + 'features';
    return this.http.post(url, obj);
  }
  featureList_conditon(appCode: any) {
    const url = this.configUrl + 'features/list';
    return this.http.get(url, { params: { appCode } });
  }
  featureList(appCode: any) {
    const url = this.configUrl + 'features/appCode';
    return this.http.get(url, { params: { appCode } });
  }
  featureDelete(id, appCode) {
    const url = this.configUrl + 'features/delete';
    return this.http.put(url, null, { params: { id, appCode } });
  }
  featureSodDelete(id, appCode) {
    const url = this.configUrl + 'features/soddelete';
    return this.http.put(url, null, { params: { id, appCode } });
  }
  featureDetails(id) {
    const url = this.configUrl + 'features/id';
    return this.http.get(url, { params: { id } });
  }
  featureUpdate(obj, id) {
    const url = this.configUrl + 'features/' + id;
    return this.http.put(url, obj);
  }
  // ----------------------------------------orchestration----------------------
  orchestrationCreation(obj) {
    const url = this.configUrl + 'orchestration';
    return this.http.post(url, obj);
  }
  orchestrationList(appCode) {
    const url = this.configUrl + 'orchestration/appCode';
    return this.http.get(url, { params: { appCode } });
  }
  orchestrationDelete(id, appCode) {
    const url = this.configUrl + 'orchestration/delete';
    return this.http.put(url, null, { params: { id, appCode } });
  }
  orchestrationActionDelete(id, appCode) {
    const url = this.configUrl + 'orchestration/actiondelete';
    return this.http.put(url, null, { params: { id, appCode } });
  }
  orchestrationUpdate(obj, id) {
    const url = this.configUrl + 'orchestration/' + id;
    return this.http.put(url, obj);
  }
  orchestrationDetails(id) {
    const url = this.configUrl + 'orchestration/id';
    return this.http.get(url, { params: { id } });
  }
  // ------------------------------------ objects ------------------------------------------------
  objectList_Code(appCode) {
    const url = this.configUrl + 'data-objects/appCode';
    return this.http.get(url, { params: { appCode } });
  }
  objectCreate(objects) {
    const url = this.configUrl + 'data-objects';
    return this.http.post(url, objects);
  }

  objectCreate_json(objects) {
    const url = this.configUrl + 'data-objects/json';
    return this.http.post(url, objects);
  }

  ObjectUpdate(obj, id) {
    const url = this.configUrl + 'data-objects/' + id;
    return this.http.put(url, obj);
  }

  objectDelete(id, appCode) {
    const url = this.configUrl + 'data-objects/delete';
    return this.http.put(url, null, { params: { id, appCode } });
  }

  objectKeyDelete(id, appCode) {
    const url = this.configUrl + 'data-objects/keydelete';
    return this.http.put(url, null, { params: { id, appCode } });
  }

  objectDetails(id) {
    const url = this.configUrl + 'data-objects/id';
    return this.http.get(url, { params: { id } });
  }
  fileConvertion(obj) {
    const url = this.configUrl + 'data-objects/xml2json';
    return this.http.post(url, obj);
  }

  fixedLengthAPI(obj) {
    const url = this.configUrl + 'data-objects/fixedlength';
    return this.http.post(url, obj);
  }

  deLimitedApi(obj) {
    const url = this.configUrl + 'data-objects/delimited';
    return this.http.post(url, obj);
  }
  // ------------------------------------------------ objectMap ---------------------------------------------------
  objectMapList(appCode) {
    const url = this.configUrl + 'object-mapping/appCode';
    return this.http.get(url, { params: { appCode } });
  }
  ObjectMapDelete(id, appCode) {
    const url = this.configUrl + 'object-mapping/delete';
    return this.http.put(url, null, { params: { id, appCode } });
  }
  ObjectMapListDelete(souId, tarId, appCode) {
    const url = this.configUrl + 'object-mapping/mapdelete';
    return this.http.put(url, null, { params: { souId, tarId, appCode } });
  }
  objectMapCreate(obj, appCode) {
    const url = this.configUrl + 'object-mapping';
    return this.http.post(url, obj, { params: { appCode } });
  }
  objectMapUpdate(obj, id) {
    const url = this.configUrl + 'object-mapping';
    return this.http.put(url, obj, { params: { id } });
  }
  objectMapDetails(id, appCode) {
    const url = this.configUrl + 'object-mapping/detailedObjects';
    return this.http.get(url, { params: { id, appCode } });
  }

  // ---------------------------------------------------------application config Count ------------------------------------
  applicationConfigCount(appCode) {
    const url = this.configUrl + 'application/count';
    return this.http.get(url, { params: { appCode } });
  }
  applicationCreate(obj) {
    const url = this.configUrl + 'application';
    return this.http.post(url, obj);
  }
  applicationList() {
    const url = this.configUrl + 'application';
    return this.http.get(url);
  }
  applicationUpdate(obj, id) {
    const url = this.configUrl + 'application';
    return this.http.put(url, obj, { params: { id } });
  }
  applicationDelete(id) {
    const url = this.configUrl + 'application/delete';
    return this.http.put(url, null, { params: { id } });
  }
  // ----------------------------------------------------- Advanced Options ----------------------------------------------------------

  // ----------------------------------------------------- Organization --------------------------------------------------------------
  orgainzation_list() {
    const url = this.configUrl + 'organization';
    return this.http.get(url);
  }
  organizationCreate(obj) {
    const url = this.configUrl + 'organization';
    return this.http.post(url, obj);
  }
  organizationDetails(id) {
    const url = this.configUrl + 'organization/id';
    return this.http.get(url, { params: { id } });
  }
  organizationDelete(id) {
    const url = this.configUrl + 'organization/delete';
    return this.http.put(url, null, { params: { id } });
  }
  organizaitonUpdate(obj, id) {
    const url = this.configUrl + 'organization';
    return this.http.put(url, obj, { params: { id } });
  }
  // ------------------------------------------------------ organization type -------------------------------------------------------
  organzationType_list() {
    const url = this.configUrl + 'organization_type';
    return this.http.get(url);
  }
  organizationTypeDelete(id) {
    const url = this.configUrl + 'organization_type/delete';
    return this.http.put(url, null, { params: { id } });
  }
  organisationTypeCreate(obj) {
    const url = this.configUrl + 'organization_type';
    return this.http.post(url, obj);
  }
  organizationTypeUpdate(obj, id) {
    const url = this.configUrl + 'organization_type';
    return this.http.put(url, obj, { params: { id } });
  }
  organizationTypeDetails(id) {
    const url = this.configUrl + 'organization_type/id';
    return this.http.get(url, { params: { id } });
  }
  // ----------------------------------------------------- site type ------------------------------------------------------
  siteTypeList() {
    const url = this.configUrl + 'site_type';
    return this.http.get(url);
  }
  siteTypeCreate(obj) {
    const url = this.configUrl + 'site_type';
    return this.http.post(url, obj);
  }
  siteTypeUpdate(obj, id) {
    const url = this.configUrl + 'site_type';
    return this.http.put(url, obj, { params: { id } });
  }
  siteTypeDelete(id) {
    const url = this.configUrl + 'site_type/delete';
    return this.http.put(url, null, { params: { id } });
  }
  siteTypeDetails(id) {
    const url = this.configUrl + 'site_type/id';
    return this.http.get(url, { params: { id } });
  }
  // ---------------------------------------------------- role --------------------------------------------------------
  roleCreate(roles) {
    const url = this.configUrl + 'roles';
    return this.http.post(url, roles);
  }
  roleList() {
    const url = this.configUrl + 'roles';
    return this.http.get(url);
  }
  roleDetails(id) {
    const url = this.configUrl + 'roles/id';
    return this.http.get(url, { params: { role_id: id } });
  }
  roleUpdate(obj, code) {
    const url = this.configUrl + 'roles';
    return this.http.put(url, obj, { params: { roleCode: code } });
  }
  roleDelete(id) {
    const url = this.configUrl + 'roles/delete';
    return this.http.put(url, null, { params: { id } });
  }

  // ------------------------------------------------------ User --------------------------------------------------------------

  userCreation(user) {
    const url = this.configUrl + 'users';
    return this.http.post(url, user);
  }
  userList() {
    const url = this.configUrl + 'users';
    return this.http.get(url);
  }
  userDetails(id) {
    const url = this.configUrl + 'users/id';
    return this.http.get(url, { params: { id } });
  }
  userUpdate(obj, id) {
    const url = this.configUrl + 'users';
    return this.http.put(url, obj, { params: { id } });
  }
  userDelete(id) {
    const url = this.configUrl + 'users/delete';
    return this.http.put(url, null, { params: { id } });
  }
  // ---------------------------------------------------- feature role mapping --------------------------------------------------

  featureRoleMapList() {
    const url = this.configUrl + 'featurerolemapping';
    return this.http.get(url);
  }
  featureRoleMapCreation(obj) {
    const url = this.configUrl + 'featurerolemapping';
    return this.http.post(url, obj);
  }
  featureRoleMapDetails(id) {
    const url = this.configUrl + 'featurerolemapping/id';
    return this.http.get(url, { params: { id } });
  }
  featureRoleMapUpdate(obj, id) {
    console.log("idid", id)
    const url = this.configUrl + 'featurerolemapping';
    return this.http.put(url, obj, { params: { id } });
  }
  featureRoleMapDelete(id) {
    const url = this.configUrl + 'featurerolemapping/delete';
    return this.http.put(url, null, { params: { id } });
  }
  // ----------------------------------------------------- user role map --------------------------------------------------------------
  userRoleMapCreation(obj) {
    const url = this.configUrl + 'userrolemapping';
    return this.http.post(url, obj);
  }

  // --------------------------------------------------- Application flow ----------------------------------------------------
  featureSpecs(appCode, ftrCode) {
    const url = this.configUrl + 'feature-specs/' + appCode + '/' + ftrCode;
    return this.http.get(url);
  }

  //-----------------------------------------------------newHlf flow ------------------------------------------------------
  hlfNetworksList() {
    const url = this.configUrl + 'hlf/networks';
    return this.http.get(url, { observe: 'response' });
  }
  createHlfNetwork(data: any) {
    const url = this.configUrl + 'hlf/networks';
    return this.http.post(url, data, { observe: 'response' });
  }

  loadNetwork(networkName: string) {
    const url = this.configUrl + 'hlf/networks/' + networkName;
    return this.http.get(url, { observe: 'response' });
  }

  updateNetwork(networkName: string, data: any) {
    const url = this.configUrl + 'hlf/networks/' + networkName;
    return this.http.put(url, data, { observe: 'response' });
  }

  updateSdkFilesNetwork(networkName: string) {
    const url = this.configUrl + 'hlf/networks/' + networkName + '/update-sdk-files';
    return this.http.put(url, {});
  }

  //----------------------------------------------------- API Documentation ------------------------------------------------------
  generateApiDocs(appCode: string) {
    const url = this.configUrl + 'application/' + appCode + '/generate';
    return this.http.put(url, {});
  }
  // -------------------------------------Login API -------------------------------------------------
  loginSubmit(data,realm) {
    let formBody: any = [];
    for (let property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);

    }
    formBody = formBody.join("&");
    let url; 
    if(realm != null){
      url = environment.keycloakConfig.url
      + '/realms/' + realm + '/protocol/openid-connect/token'
    }else{
      url = environment.keycloakConfig.url
      + '/realms/' + environment.keycloakConfig.realm + '/protocol/openid-connect/token'
    }
   
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    // console.log(params, params.toString(), "params")
    return this.http.post(url, formBody, options)
  }


  getaccount() {
    const url =
      console.log(this.headers, sessionStorage.getItem('token'), "header")
    return this.http.get(this.baseUrl1 + '/account', {
      headers: this.headers
    });
  }




















  //-------------------------------- Products api -----------------------------

  getProducts(){
    const url = this.baseUrl + 'Organization/getOrgCount';
    return this.http.get(url)
  }


//----------------------------------add-customer Dropdowm api-------------------

  getState(){
    const url=this.baseUrl+ 'States/getStates';
    return this.http.get(url)
  }

  getDistrict(params){
    const url= this.baseUrl+'Districts/getDistricts'
    return this.http.get(url,{params:{state_id:params.state_id}})
  }

  getDesignation(){
    const url=this.baseUrl+'RoleMaster/getRoleMaster'
    return this.http.get(url)
  }
  getArea(params){
    const url=this.baseUrl+'Areas/getAreas';
    return this.http.get(url,{params:{district_id:params.district_id}})
  }
  getProductByCustomer(){
    const url= this.baseUrl + 'Organization/getOrganization';
    return this.http.get(url)
  }
  getCustomerCount(){
    const url = this.baseUrl + 'Organization/getOrgCount';
    return this.http.get(url)

  }


//-------------------------------coustmer-list api--------------------------

  GetAllCoustomer(){
    const url=this.baseUrl+'Organization/getOrganization'
    return this.http.get(url)
    

  }
  getRoleByProduct(){
    const url = this.baseUrl + 'ProductMapping/getRolesByProducts';
    return this.http.get(url,{params:{user_id:sessionStorage.getItem("preferred_username")}}) 
  }
  getExistingOrganization(req){
    const url = this.baseUrl + 'Organization/existingCustomersList';
    return this.http.get(url,{params:{product_code:req}})
  }

  existingCustomerProduct(req){
    const url  = this.baseUrl + 'Organization/add_existing_customer_product';
    return this.http.post(url,req)
  }
  createNewOrganization(req){
    const url = this.baseUrl + 'Organization/createOrganization';
    return this.http.post(url,req)
  }
}